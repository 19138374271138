<template>
  <div class="buyer-recherche">
    <h1>C'est si facile d'acheter une voiture d'occasion</h1>
    <p>
      Choisissez, financez en ligne, faites-vous livrer. Vous l'adorez ou vous
      êtes remboursé
    </p>
    <div id="searchBar">
      <div class="search-container">
        <div class="algolia-container">
          <v-select
            :placeholder="'Sélectionner la marque'"
            :options="searchResult"
            @input="onChangeSelectMake($event)"
          ></v-select>
          <v-select
            :placeholder="'Sélectionner le modèle'"
            :options="searchResultModel"
            :class="searchData.make === '' ? 'no-validate' : ''"
            :disabled="searchData.make === '' ? true : false"
            @input="onChangeSelectModel($event)"
          ></v-select>
        </div>
        <button class="search-button" @click="goToResults()">
          Découvrir les voitures
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4 col-md-4 col-lg-4 validate">
        <img
          :src="getPicto('aloneValidate', 'svg', 'classique')"
          :alt="'Garantie jusqu\'à 12 mois'"
        />
        <span>Garantie jusqu'à 12 mois</span>
      </div>
      <div class="col-sm-4 col-md-4 col-lg-4 validate">
        <img
          :src="getPicto('aloneValidate', 'svg', 'classique')"
          :alt="'Satisfait ou remboursé'"
        />
        <span>Satisfait ou remboursé</span>
      </div>
      <div class="col-sm-4 col-md-4 col-lg-4 validate">
        <img
          :src="getPicto('aloneValidate', 'svg', 'classique')"
          :alt="'Livraison à domicile'"
        />
        <span>Livraison à domicile</span>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-12 cgv">
        <a target="_blank" :href="validateUrl('/donnees-personnelles')">
          Informations sur vos données personnelles
        </a>
      </div>
    </div>
  </div>
</template>

<script>
require('dotenv').config()
import algoliasearch from "algoliasearch";
import vSelect from "vue-select";
import UtilMixin from "../../../mixins/UtilMixin";


export default {
  name: "BuyerRchercheModule",
  components: {
    vSelect,
  },
  mixins: [UtilMixin],
  data() {
    return {
      route: "",
      algolia: "",
      algolia2: "",
      partner: null,
      currentMake: null,
      searchResult: [],
      makes: [
        "RENAULT",
        "DACIA",
        "CITROEN",
        "PEUGEOT",
        "VOLKSWAGEN",
        "AUDI",
        "MERCEDES",
        "ABARTH",
        "ALFA ROMEO",
        "ALPINE",
        "ASTON MARTIN",
        "BMW",
        "BMW i",
        "CHEVROLET",
        "CHRYSLER",
        "DAIHATSU",
        "DODGE",
        "DS",
        "FIAT",
        "FORD",
        "HONDA",
        "HYUNDAI",
        "INFINITI",
        "JAGUAR",
        "JEEP",
        "KIA",
        "LANCIA",
        "LAND ROVER",
        "LEXUS",
        "MASERATI",
        "MAZDA",
        "MINI",
        "MITSUBISHI",
        "NISSAN",
        "OPEL",
        "PORSCHE",
        "SAAB",
        "SEAT",
        "SKODA",
        "SMART",
        "SUBARU",
        "SUZUKI",
        "TESLA",
        "TOYOTA",
        "VOLVO",
      ],
      searchResultModel: [],
      searchData: {
        make: "",
        model: "",
        version: "",
        inputValue: "",
        inputValueModel: "",
      },
    };
  },
  mounted() {
    const client = algoliasearch(
        `${process.env.VUE_APP_ALGOLIA_APP_ID}`,
        `${process.env.VUE_APP_ALGOLIA_API_KEY}`
    );
    this.algolia = client.initIndex(`${process.env.VUE_APP_ALGOLIA_INDEX}`);

    this.partner = this.getPartenaire().subdomain
    this.route = this.getPartenaire().route;
    this.searchResult = this.makes.sort();
  },
  methods: {
    modelListReset(make) {
      const checkModelList = [];
      this.algolia.search({ hitsPerPage: 1000 }).then((hits) => {
        for (const element of hits.hits) {
          if (element.make === make) {
            if (!checkModelList.includes(element.model)) {
              checkModelList.push(element.model);
            }
          }
        }
      });
      return checkModelList;
    },
    onChangeSelectMake(make) {
      this.searchData.make = make;
      this.searchResultModel = this.modelListReset(make);
    },
    onChangeSelectModel(model) {
      this.searchData.model = model;
    },
    validateUrl(url) {
      return url + this.route;
    },
    goToResults() {
      let adsUrl = "";
      let modelUrl = this.searchData.model !== '' ? `/${this.searchData.model}` : "";
      this.eventTracking(
          'acheter_voiture',
          'clics_module',
          'popin',
          this.searchData.make,
          this.searchData.model
      )
      const carParams =
        this.searchData.make !== ""
          ? `${this.searchData.make}-occasion/${this.searchData.model}`
          : `voiture-occasion${this.searchData.inputValue}`;

      adsUrl = `/${carParams.toLowerCase()}${this.route}`;
      window.location.href = adsUrl.replaceAll(" ", "-");
    }
  },
};
</script>
<style lang="scss">
@import "../../../assets/sass/modules/_buyerRechercheModule.scss";
@import "../../../assets/sass/modules/vueSelect/vue-select.scss";
</style>
