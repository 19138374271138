<template>
  <div :data-v="'v-1.1.0'">
    <banners-module v-if="name === 'BannersModule'" />
    <google-avis-panel v-if="name.includes('avis')" :avis="storage" />
    <header-funnel-module v-if="name === 'HeaderFunnelModule'" />
    <header-module v-if="name === 'HeaderModule'" />
    <footer-panel v-if="name === 'FooterModule'" :organisation="organisation" />
    <recherche-module
      v-if="name === 'rechercheModule'"
      :type="type"
      :hp-type="hpType"
      :display-title="displayTitle"
    />
    <makes-module v-if="name === 'makeHp'" />
    <licence-plate v-if="name === 'licencePLIMIT_YEAR=11late'" :title="title" />
  </div>
</template>

<script>
import HeaderModule from "../../../components/modules/layouts/HeaderModule";
import HeaderFunnelModule from "../../../components/modules/layouts/HeaderFunnelModule";
import FooterPanel from "../../../components/panels/layouts/FooterPanel";
import rechercheModule from "../../../components/modules/commons/rechercheModule";
import licencePlate from "../../../components/modules/commons/licencePlateModule";
import makesModule from "../../../components/modules/commons/MakesModule";
import googleAvisPanel from "../../../components/panels/avis/googleAvisPanel";
import { store, mutations } from "../../../store/avis";
import stores from '../../../store/header'
import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from '../../../node_modules/bootstrap-vue'

// Import Bootstrap and BootstrapVue CSS files (order is important)
import '../../../node_modules/bootstrap/dist/css/bootstrap.css'
import '../../../node_modules/bootstrap-vue/dist/bootstrap-vue.css'
import localStorage from '../../../helpers/localStorage'
import BannersModule from '../../../components/modules/banners/bannersModule.vue'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

require('dotenv').config()

export default {
  name: "VueClownComponent",
  components: {
    BannersModule,
    FooterPanel,
    HeaderModule,
    rechercheModule,
    HeaderFunnelModule,
    makesModule,
    licencePlate,
    googleAvisPanel
  },
  props: {
    name: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "Estimer gratuitement ma voiture",
    },
    type: {
      type: String,
      default: "",
    },
    displayTitle: {
      type: Boolean,
      required: false,
      default: true,
    },
    organisation: {
      type: String,
      default: "",
    },
    hpType: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      pageSize: 5,
      store,
      stores,
      storage: null
    }
  },
  created () {
    if (this.name.includes('avis')) this.pageSize = this.name.replace('avis-', '')
    this.storage = JSON.parse(window.localStorage.getItem(store.localStorageKey))
  },
  async mounted() {
    if (store.data.length === 0){
      await fetch(
          `https://oauth2.googleapis.com/token`,
          {
            method: 'POST',
            body: `client_id=${encodeURIComponent(process.env.VUE_APP_GOOGLE_CLIENT_ID)}&client_secret=${encodeURIComponent(process.env.VUE_APP_GOOGLE_CLIENT_SECRET)}&grant_type=refresh_token&refresh_token=${encodeURIComponent(process.env.VUE_APP_GOOGLE_AVIS_REFRESH_TOKEN)}`,
            responseType:'json',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          }
      )
          .then(response => response.json())
          .then( json => mutations.setToken(json.access_token));
    }
    if (store.token) {
      localStorage.methods.removeData();
      await fetch(
          `https://mybusiness.googleapis.com/v4/accounts/${process.env.VUE_APP_GOOGLE_AVIS_ACCOUNTS}/locations/${process.env.VUE_APP_GOOGLE_AVIS_LOCATIONS}/reviews?pageSize=${this.pageSize}&orderBy=rating desc`,
          {
            method: 'GET',
            responseType: 'json',
            headers: {
              authorization: `Bearer ${store.token}`,
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          }
      )
          .then(response => response.json())
          .then(json =>  mutations.setData(json));
      localStorage.methods.saveData()
    }
    const queryAlert = `
    	query {
        getAlert {
          content
        }
      }
    `
    const queryBanner = `
    	query {
        getBanner {
          link
          wordingCTA
          content
        }
      }
    `
    fetch(`${process.env.VUE_APP_API_URL}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.VUE_APP_API_TOKEN}`,
      },
      body: JSON.stringify({ query: queryAlert })
    })
        .then(response => response.json())
        .then(json => {
          stores.altert = json.data.getAlert.content
        })

    fetch(`${process.env.VUE_APP_API_URL}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.VUE_APP_API_TOKEN}`,
      },
      body: JSON.stringify({ query: queryBanner })
    })
        .then(response => response.json())
        .then(json => {
          stores.banner = json.data.getBanner
        })
  }
};
</script>

<style lang="scss">
@import "~bootstrap/scss/bootstrap";
@import "../../../node_modules/bootstrap/scss/_variables.scss";
@import "../../../node_modules/bootstrap/scss/_functions.scss";
@import "../../../node_modules/bootstrap/scss/_mixins.scss";
@import "../../../assets/sass/base/carizy/variable.scss";
@import "../../../assets/sass/base/carizy/base.scss";
</style>
